import { default as _91form_93hFLVgl4TFYMeta } from "/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as deniedQRUmT5kLu8Meta } from "/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finishBPXo7yhsBPMeta } from "/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as interactionxtXq1gAAOiMeta } from "/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/interaction.vue?macro=true";
import { default as startjp4mVFp3yXMeta } from "/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_93ftVB7uq4XdMeta } from "/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexMJ3dvdSGaoMeta } from "/codebuild/output/src1439315736/src/form.winx.ai/pages/index.vue?macro=true";
import { default as loginNCQ6tyhXnDMeta } from "/codebuild/output/src1439315736/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirectUpVZLVvJYXMeta } from "/codebuild/output/src1439315736/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: _91_91research_93_93ftVB7uq4XdMeta?.name ?? "research",
    path: _91_91research_93_93ftVB7uq4XdMeta?.path ?? "/:research?",
    meta: _91_91research_93_93ftVB7uq4XdMeta || {},
    alias: _91_91research_93_93ftVB7uq4XdMeta?.alias || [],
    redirect: _91_91research_93_93ftVB7uq4XdMeta?.redirect,
    component: () => import("/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]].vue").then(m => m.default || m),
    children: [
  {
    name: _91form_93hFLVgl4TFYMeta?.name ?? "research-form",
    path: _91form_93hFLVgl4TFYMeta?.path ?? ":form()",
    meta: _91form_93hFLVgl4TFYMeta || {},
    alias: _91form_93hFLVgl4TFYMeta?.alias || [],
    redirect: _91form_93hFLVgl4TFYMeta?.redirect,
    component: () => import("/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/[form].vue").then(m => m.default || m)
  },
  {
    name: deniedQRUmT5kLu8Meta?.name ?? "research-denied",
    path: deniedQRUmT5kLu8Meta?.path ?? "denied",
    meta: deniedQRUmT5kLu8Meta || {},
    alias: deniedQRUmT5kLu8Meta?.alias || [],
    redirect: deniedQRUmT5kLu8Meta?.redirect,
    component: () => import("/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/denied.vue").then(m => m.default || m)
  },
  {
    name: finishBPXo7yhsBPMeta?.name ?? "research-finish",
    path: finishBPXo7yhsBPMeta?.path ?? "finish",
    meta: finishBPXo7yhsBPMeta || {},
    alias: finishBPXo7yhsBPMeta?.alias || [],
    redirect: finishBPXo7yhsBPMeta?.redirect,
    component: () => import("/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/finish.vue").then(m => m.default || m)
  },
  {
    name: interactionxtXq1gAAOiMeta?.name ?? "research-interaction",
    path: interactionxtXq1gAAOiMeta?.path ?? "interaction",
    meta: interactionxtXq1gAAOiMeta || {},
    alias: interactionxtXq1gAAOiMeta?.alias || [],
    redirect: interactionxtXq1gAAOiMeta?.redirect,
    component: () => import("/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/interaction.vue").then(m => m.default || m)
  },
  {
    name: startjp4mVFp3yXMeta?.name ?? "research-start",
    path: startjp4mVFp3yXMeta?.path ?? "start",
    meta: startjp4mVFp3yXMeta || {},
    alias: startjp4mVFp3yXMeta?.alias || [],
    redirect: startjp4mVFp3yXMeta?.redirect,
    component: () => import("/codebuild/output/src1439315736/src/form.winx.ai/pages/[[research]]/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexMJ3dvdSGaoMeta?.name ?? "index",
    path: indexMJ3dvdSGaoMeta?.path ?? "/",
    meta: indexMJ3dvdSGaoMeta || {},
    alias: indexMJ3dvdSGaoMeta?.alias || [],
    redirect: indexMJ3dvdSGaoMeta?.redirect,
    component: () => import("/codebuild/output/src1439315736/src/form.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginNCQ6tyhXnDMeta?.name ?? "login",
    path: loginNCQ6tyhXnDMeta?.path ?? "/login",
    meta: loginNCQ6tyhXnDMeta || {},
    alias: loginNCQ6tyhXnDMeta?.alias || [],
    redirect: loginNCQ6tyhXnDMeta?.redirect,
    component: () => import("/codebuild/output/src1439315736/src/form.winx.ai/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectUpVZLVvJYXMeta?.name ?? "redirect",
    path: redirectUpVZLVvJYXMeta?.path ?? "/redirect",
    meta: redirectUpVZLVvJYXMeta || {},
    alias: redirectUpVZLVvJYXMeta?.alias || [],
    redirect: redirectUpVZLVvJYXMeta?.redirect,
    component: () => import("/codebuild/output/src1439315736/src/form.winx.ai/pages/redirect.vue").then(m => m.default || m)
  }
]