<template>
  <div class="group inline-flex items-center justify-center text-right">
    <Menu as="div" class="relative z-20 size-10 text-start">
      <MenuButton as="template">
        <button
          v-if="$user.value?.avatar"
          type="button"
          class="group-hover:ring-primary-500 dark:ring-offset-muted-800 inline-flex size-10 items-center justify-center rounded-full ring-1 ring-transparent transition-all duration-300 group-hover:ring-offset-4"
        >
          <div class="relative inline-flex size-10 items-center justify-center rounded-full">
            <NuxtImg :src="$user.value?.avatar" class="max-w-full rounded-full object-cover shadow-sm dark:border-transparent" alt="Avatar" />
          </div>
        </button>
        <button
          v-else
          type="button"
          class="ring-muted-300 dark:ring-muted-700 dark:border-muted-70 group-hover:ring-primary-500 dark:ring-offset-muted-900 inline-flex size-9 items-center justify-center rounded-full ring-1 ring-transparent transition-all duration-300 group-hover:ring-offset-4"
        >
          <div class="relative inline-flex size-9 items-center justify-center rounded-full">
            <NuxtImg src="cdn/images/avatars/avatar-icon.png" alt="Avatar" />
          </div>
        </button>
      </MenuButton>

      <Transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="border-muted-200 dark:border-muted-700 dark:bg-muted-800 absolute mt-2 w-60 origin-bottom-right rounded-md border bg-white text-left shadow-lg focus:outline-none"
          :class="props.horizontal ? 'top-10 end-0' : 'bottom-0 -end-64'"
        >
          <div class="bg-muted-50 dark:bg-muted-700/40 p-6">
            <div class="flex items-center">
              <div class="relative inline-flex size-14 items-center justify-center rounded-full">
                <NuxtImg :src="$user.value?.avatar || 'cdn/images/avatars/avatar-icon.png'" class="max-w-full rounded-full object-cover shadow-sm dark:border-transparent" alt="Avatar" />
              </div>
              <div class="ms-3">
                <h6 class="font-heading text-muted-800 text-sm font-medium dark:text-white">Colaborador(a)</h6>
              </div>
            </div>
          </div>
          <div class="p-2">
            <slot name="menu-item" />
            <MenuItem as="div">
              <div class="p-6">
                <BaseButton rounded="lg" class="w-full" @click.passive="$auth.logout()"> {{ $t('wb.logout') }} </BaseButton>
              </div>
            </MenuItem>
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  </div>
</template>

<script lang="ts" setup>
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

  const props = defineProps<{
    horizontal?: boolean;
  }>();
</script>
